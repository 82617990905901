<template>
  <div>
    <h1 class="-mt-3">ORGANIZAČNÍ VÝBOR</h1>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-8 p-text-left h1-leading">
      <div class="mb-6">
        <h1>PAVEL<br>DOBEŠ</h1>
        <p>Politik, zastupitel a&nbsp;radní Městské části Praha&nbsp;3.</p>
      </div>
      <div class="mb-6">
        <h1>NADĚŽDA<br>GORYCZKOVÁ</h1>
        <p>Generální ředitelka Národního památkového ústavu. Odborně se specializuje na památky moderní architektury.</p>
      </div>
      <div class="mb-6">
        <h1>PETR<br>HÁJEK</h1>
        <p>Architekt a&nbsp;pedagog, profesor na Fakultě architektury ČVUT v&nbsp;Praze. </p>
      </div>
      <div class="mb-6">
        <h1>JAN<br>KASL</h1>
        <p>Architekt a&nbsp;politik, předseda České komory architektů.</p>
      </div>
      <div class="mb-6">
        <h1>KAREL<br>KSANDR</h1>
        <p>Historik architektury, památkář a&nbsp;muzejník, generální ředitel Národního technického muzea v&nbsp;Praze.</p>
      </div>
      <div class="mb-6">
        <h1>IMRO<br>VAŠKO</h1>
        <p>Architekt, teoretik architektury a&nbsp;kurátor, profesor architektury na Vysoké škole uměleckoprůmyslové v&nbsp;Praze.</p>
      </div>
    </div>
    <hr class="border-black mt-6 mb-8">
    <h1>VÝKONNÁ JEDNOTKA</h1>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-8 p-text-left h1-leading">
      <div class="mb-6">
        <h1>DAN<br>MERTA</h1>
        <p>Historik umění a&nbsp;kurátor, ředitel Galerie Jaroslava Fragnera v&nbsp;Praze.</p>
      </div>
      <div class="mb-6">
        <h1>JAKUB<br>POTŮČEK</h1>
        <p>Historik umění a&nbsp;kurátor specializující se na moderní architekturu.</p>
      </div>
      <div class="mb-6">
        <h1>DUŠAN<br>SEIDL</h1>
        <p>Kurátor a&nbsp;editor, šéf oddělení prezentace sbírek Uměleckoprůmyslového musea v&nbsp;Praze.</p>
      </div>
      <div class="mb-6">
        <h1>RENATA<br>VRABELOVÁ</h1>
        <p>Architektka, kurátorka a&nbsp;odbornice Metodického centra moderní architektury Národního památkového ústavu v&nbsp;Brně.</p>
      </div>
    </div>
    <hr class="border-black mt-6 mb-8">
    <h1>ČESTNÝ VÝBOR</h1>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-8 p-text-left h1-leading">
      <div class="mb-6">
        <h1>AARON<br>BETSKY</h1>
        <p>Kritik umění, architektury a&nbsp;designu, ředitel Školy architektury a&nbsp;designu na Virginia Tech.</p>
      </div>
      <div class="mb-6">
        <h1>KRISTIN<br>FEIREISS</h1>
        <p>Kurátorka architektonických výstav, spisovatelka a&nbsp;editorka, spoluzakladatelka Aedes Architecture Forum v&nbsp;Berlíně.</p>
      </div>
      <div class="mb-6">
        <h1>ANGELIKA<br>FITZ</h1>
        <p>Historička umění a&nbsp;kurátorka, ředitelka Architekturzentrum Wien.</p>
      </div>
      <div class="mb-6">
        <h1>BENJAMIN<br>FRAGNER</h1>
        <p>Historik architektury a&nbsp;industriální archeolog, šéf Výzkumného centra průmyslového dědictví (VCPD) na Fakultě architektury ČVUT v&nbsp;Praze.</p>
      </div>
      <div class="mb-6">
        <h1>JOLANTA<br>GROMADZKA</h1>
        <p>Zástupce ředitele Muzea architektury ve Vratislavi pro sbírkovou činnost.</p>
      </div>
      <div class="mb-6">
        <h1>HANS<br>IBELINGS</h1>
        <p>Historik architektury a&nbsp;kritik, profesor na Fakultě architektury Torontské univerzity.</p>
      </div>
      <div class="mb-6">
        <h1>PETR<br>KRATOCHVÍL</h1>
        <p>Historik a&nbsp;teoretik moderní architektury a&nbsp;urbanismu, pedagog, profesor architektury, vědecký pracovník Ústavu dějin umění Akademie věd České republiky.</p>
      </div>
      <div class="mb-6">
        <h1>MIROSLAV<br>MASÁK</h1>
        <p>Architekt a&nbsp;pedagog, člen Sdružení inženýrů a&nbsp;architektů v&nbsp;Liberci (SIAL) a&nbsp;bývalý poradce prezidenta Václava Havla.       </p>
      </div>
      <div class="mb-6">
        <h1>TEREZIE<br>NEKVINDOVÁ</h1>
        <p>Historička a&nbsp;teoretička architektury a&nbsp;výtvarného umění, vědecká pracovnice Katedry teorie a&nbsp;dějin umění Akademie výtvarných umění v&nbsp;Praze.</p>
      </div>
      <div class="mb-6">
        <h1>JIŘÍ<br>PŘÍHODA</h1>
        <p>Výtvarný umělec, který působí na pomezí sochařství, objektu a&nbsp;architektury. Je autorem architektonického designu řady výstav a&nbsp;expozic v&nbsp;galeriích a&nbsp;muzeích umění.</p>
      </div>
      <div class="mb-6">
        <h1>JINDŘICH<br>VYBÍRAL</h1>
        <p>Historik a&nbsp;teoretik architektury a&nbsp;výtvarného umění, editor, profesor a&nbsp;rektor Vysoké školy uměleckoprůmyslové v&nbsp;Praze.</p>
      </div>
      <div class="mb-6">
        <h1>PETR<br>ZEMAN</h1>
        <p>Spoluzakladatel spolku Prázdné domy, zastupitel Hlavního města Prahy a&nbsp;předseda Výboru pro územní rozvoj, územní plánování a&nbsp;památkovou péči ZHMP a&nbsp;Komise Rady hl.&nbsp;m.&nbsp;Prahy pro koordinaci neinvestičních aktivit ve veřejných prostranstvích na území Pražské památkové rezervace. </p>
      </div>
      <div class="mb-6">
        <h1>IRENA<br>ŽANTOVSKÁ<br>MURRAY</h1>
        <p>Historička umění a&nbsp;kurátorka, pracovala jako ředitelka sbírky a&nbsp;knihovny Sira Banistera Fletchera v&nbsp;Royal Institute of British Architects (RIBA) v&nbsp;Londýně.</p>
      </div>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

export default {
  name: 'OrganizacniStruktura',
  components: {
  },
  mounted() {
    window.scrollTo({ top: 0});
  },
}
</script>

<style>
.p-text-left p{
  text-align: left;
}
.h1-leading h1{
  line-height: 1.5;
}
</style>